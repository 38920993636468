export const isValidMaterialName = (materialName: string) => {
  return (
    [
      'poster',
      'canvas',
      'poster_plastic',
      'poster_wooden_frame',
      'poster_metal_frame',
      'wooden',
      'alu',
      'acryl',
      'galleryprint',
      'cups',
      'cases',
      'giftvoucher',
      'wallcalendar',
      'deskcalendar',
      'familyplaner',
      'foldingcalendar',
      'squarecalendar',
      'puzzle',
      'pillow',
      'magnet',
      'placemat',
      'advent',
      'tshirt',
      'hoodie',
      'sweatshirt',
      'metalposter',
    ].indexOf(materialName) >= 0
  )
}

export const areProductOverviewParametersValid = (
  materialName: string,
  page: number,
  categories: string[],
  sortingOrder: string,
  location: Location,
) => {
  if (!isValidMaterialName(materialName)) {
    console.log('! Invalid material *' + materialName + '*')
    return false
  }

  if (
    ['bestseller', 'new-in', 'rising-stars', 'relevance'].indexOf(
      sortingOrder,
    ) === -1
  ) {
    console.log('! Invalid sorting order *' + sortingOrder + '*')

    return false
  }

  if (page < 1) {
    console.log('! Invalid page *' + page + '*')

    return false
  }

  // if (location.pathname.indexOf('all/ranking') === -1) {
  //   return false
  // }

  return true
}
