import React, { useContext, useEffect } from 'react'
import Products from '../components/products/Products'
import { areProductOverviewParametersValid } from '../utils/validators'
import { navigate } from 'gatsby'
import { getProductsTemplateUrl, isBrowser } from '../utils/utils'
import Breadcrumb from '../components/artboxone/Breadcrumb'
import { BreadcrumbLayer, PageInformation } from '../types'
import {
  mapSlug2InternalKey,
  mapInternalKey2Slug,
  mapInternalProductMaterial2InternalKey,
  parseHashUrl,
} from '../utils/UrlUtils'
import {
  useRedirectData,
  getRedirectOptionsByLocation,
} from '../lib/global/redirect_hook'
import { parseFilterUrl } from '../utils/PuesUtils'
import fetchError from '../lib/global/error_api'
import {env} from '../../environment'
import { getHash } from '../utils/Crypto'
import { useCategoriesData } from '../lib/products/categories_hook'
import { useRatiosData } from '../lib/products/ratios_hook'
import { useStylesData } from '../lib/products/styles_hook'
import { useColorsData } from '../lib/products/colors_hook'
import { useArtistsData } from '../lib/artists_hook'
import PageInformationContext from '../context/PageInformationContext'

interface ProductsPageRoutingProps {
  materialSlug: string
  filter: string
  sortingOrderSlug: string
  page: number
  location: Location
  searchQuery: string
}

const ProductsPageRouting: React.FC<ProductsPageRoutingProps> = ({
  materialSlug,
  filter,
  sortingOrderSlug,
  page,
  searchQuery,
  location,
}) => {
  let material = mapSlug2InternalKey(materialSlug)

  if (material === undefined) {
    if (materialSlug == 'frauen' || materialSlug == 'maenner') {
      material = 'tshirt'
    }
  }

  let sortingOrder = mapSlug2InternalKey(sortingOrderSlug)
  const urlSlugAll = mapInternalKey2Slug('all')

  page = parseInt('' + page)

  if (page == 0 || isNaN(page)) {
    page = 1
  }

  if (typeof sortingOrder == 'undefined') {
    sortingOrder = 'bestseller'
  }

  if (typeof searchQuery == 'undefined') {
    searchQuery = ''
  }

  // Sonderfall Magento
  let magentoRedirect = false

  if (filter === 'themen') {
    const redirectInfo: any = useRedirectData(
      getRedirectOptionsByLocation(location),
    )

    if (redirectInfo === undefined) {
      magentoRedirect = true
    } else if (redirectInfo.location != '') {
      magentoRedirect = true
    } else {
      magentoRedirect = false
    }
  }

  // console.log('magentoRedirect: ' + magentoRedirect)

  let parsedUrlFilter = undefined
  let urlfilters: string[] = []
  let finishedLoadingAdditionalData = true
  let filterUrl = urlSlugAll
  let categoriesString = filterUrl.charAt(0).toUpperCase() + filterUrl.slice(1)

  if (filter) {
    const categoryData = useCategoriesData()
    const colorData = useColorsData()
    const ratioData = useRatiosData()
    const artistData = useArtistsData()
    const stylesData = useStylesData()
    const urlSlugAll = mapInternalKey2Slug('all')
    parsedUrlFilter = parseFilterUrl(filter, categoryData, colorData, ratioData, artistData, stylesData, urlSlugAll)
    finishedLoadingAdditionalData = parsedUrlFilter.ready
    urlfilters = parsedUrlFilter.categories
      .concat(parsedUrlFilter.colors)
      .concat(parsedUrlFilter.ratios)
      .concat(parsedUrlFilter.artists)
      .concat(parsedUrlFilter.styles)
      .sort()
  }

  let variant = undefined

  if (location.hash !== '') {
    const data: any = parseHashUrl(location.hash)

    if (typeof data.variant === 'string') {
      variant = data.variant
    }
  }

  // Adventskalender Filter
  if (variant == 'ueei') {
    const querformat = 'querformat'
    const hochformat = 'hochformat'

    // if (env.getLanguage() == 'en') {
    //   querformat = 'landscape'
    //   hochformat = 'portrait'
    // }

    if (!urlfilters.includes(querformat)) {
      urlfilters.push(querformat)
    }

    if (urlfilters.includes(hochformat)) {
      urlfilters = urlfilters.filter((elem: string) => {
        return elem != hochformat
      })
    }
  }

  if (urlfilters.length > 0) {
    filterUrl = urlfilters.join('_')
    categoriesString = urlfilters
      .map((elem) => {
        return elem.charAt(0).toUpperCase() + elem.slice(1)
      })
      .join(', ')
  }

  if (isBrowser()) {
    const generatedUrl = getProductsTemplateUrl(
      material,
      urlfilters,
      sortingOrder,
      searchQuery,
      page,
      variant,
    )

    if (
      !areProductOverviewParametersValid(
        material,
        page,
        urlfilters,
        sortingOrder,
        location,
      )
    ) {
      if (!magentoRedirect) {
        fetchError({
          data:
            'Invalid PUES: ' +
            material +
            ' (' +
            materialSlug +
            ')' +
            ', ' +
            page +
            ', ' +
            JSON.stringify(urlfilters) +
            ', ' +
            sortingOrder,
        })

        navigate('/' + mapInternalKey2Slug('wandbilder', env.getLanguage()))
      }
    } else {
      // Compare current url against generated url
      // e.g. wrong categories
      // e.g. trailing /1

      let currentUrl = location.pathname

      if (variant) {
        currentUrl += '#variant=' + variant
      }

      if (
        finishedLoadingAdditionalData &&
        !magentoRedirect &&
        generatedUrl != currentUrl
      ) {
        console.log('!!! Redirect to new url !!!')
        console.log('current: ' + currentUrl)
        console.log('gen ->:  ' + generatedUrl)
        navigate(generatedUrl)
      }
    }
  }

  const signature = getHash({
    materialName: material,
    page: page,
    categories: parsedUrlFilter?.categories ?? [],
    colors: parsedUrlFilter?.colors ?? [],
    ratios: parsedUrlFilter?.ratios ?? [],
    artists: parsedUrlFilter?.artists ?? [],
    styles: parsedUrlFilter?.styles ?? [],
    sortingOrder: sortingOrder,
    searchQuery: searchQuery,
  })

  const breadcrumbLayers: BreadcrumbLayer[] = []

  let breadcrumbUrl = ''

  if (searchQuery !== '') {
    breadcrumbUrl += '/' + mapInternalKey2Slug('search')

    breadcrumbLayers.push({
      url: breadcrumbUrl,
      name: 'Suche',
    })
  } else {
    const productMaterialKey = mapInternalProductMaterial2InternalKey(material)
    breadcrumbUrl += '/' + mapInternalKey2Slug(productMaterialKey)

    breadcrumbLayers.push({
      url: breadcrumbUrl,
      name: productMaterialKey,
    })
  }

  breadcrumbUrl += '/' + mapInternalKey2Slug(material)
  breadcrumbLayers.push({
    url: breadcrumbUrl,
    name: material,
  })

  breadcrumbUrl += '/' + filterUrl
  breadcrumbLayers.push({
    name: categoriesString,
  })

  breadcrumbUrl += '/' + mapInternalKey2Slug(sortingOrder)
  breadcrumbLayers.push({
    name: sortingOrder,
  })

  const pageInformation: PageInformation = {
    type: 'pues',
    material: material,
    searchQuery: searchQuery,
    urlFilters: urlfilters,
    variant: variant,
  }
  const { resetCounter, setPageInformation } = useContext(PageInformationContext)
  useEffect(() => {
    setPageInformation(pageInformation)
  }, [resetCounter])

  return (
    <>
      <Breadcrumb layers={breadcrumbLayers} />
      <Products
        artists={parsedUrlFilter?.artists ?? []}
        categories={parsedUrlFilter?.categories ?? []}
        colors={parsedUrlFilter?.colors ?? []}
        key={signature}
        materialName={material}
        page={page}
        ratios={parsedUrlFilter?.ratios ?? []}
        searchQuery={searchQuery}
        sortingOrder={sortingOrder}
        styles={parsedUrlFilter?.styles ?? []}
        variant={variant}
      />
    </>
  )
}

export default ProductsPageRouting
