import { fetchProductData } from '../../services/product_api'
import { useState, useEffect } from 'react'
import { LoadMoreThanOneProductOptions } from '../types'
import LRU from 'lru-cache'
import { getHash } from '../utils/Crypto'

const cache = new LRU({max: 100})

export const useProductsData = (options: LoadMoreThanOneProductOptions) => {
  const [productsData, setProductsData] = useState([])

  const signature = getHash(options)

  useEffect(() => {
    const cacheResult = cache.get(signature)

    if (typeof cacheResult !== 'undefined') {
      setProductsData(cacheResult)
    } else {
      fetchProductData(options).then(response => {
        if (response.error > 0) {
          setProductsData({ error: response.data })
        } else {
          cache.set(signature, response.data)
          setProductsData(response.data)
        }
      })
    }
  }, [signature])

  return productsData
}
