import React from 'react'
import { navigate , Link } from 'gatsby'
import {
  useRedirectData,
  getRedirectOptionsByLocation,
} from '../lib/global/redirect_hook'
import ProductsPageRouting from './ProductsPageRouting'
import { withUrlSlugs } from '../components/generic/withUrlSlugs'
import withLocation from '../components/generic/withLocation'

interface ProductsPageRoutingMagentoProps {
  materialSlug: string
  filter: string
  sortingOrderSlug: string
  page: number
  location: Location
  searchQuery: string
}

const ProductsPageRoutingMagento: React.FC<ProductsPageRoutingMagentoProps> = ({
  materialSlug,
  filter,
  sortingOrderSlug,
  page,
  searchQuery,
  location,
}) => {
  let magentoRedirect = false
  let redirectInfo: any = undefined

  if (filter === 'themen' || filter == 'motivfarbe') {
    magentoRedirect = true

    redirectInfo = useRedirectData(getRedirectOptionsByLocation(location))

    if (redirectInfo !== undefined && redirectInfo.location == '') {
      magentoRedirect = false
    }
  }

  if (magentoRedirect) {
    if (redirectInfo === undefined) {
      return <></>
    } else if (redirectInfo.location != '') {
      if (process.env.NODE_ENV === `development`) {
        console.log('Redirect dev ')
        return (
          <div>
            <h1>Umleitung [DEV]</h1>
            <Link to={redirectInfo.location}>{redirectInfo.location}</Link>
          </div>
        )
      } else {
        console.log('Redirect prod')
        navigate(redirectInfo.location)
        return <></>
      }
    }
  } else {
    return (
      <ProductsPageRouting
        filter={filter}
        location={location}
        materialSlug={materialSlug}
        page={page}
        searchQuery={searchQuery}
        sortingOrderSlug={sortingOrderSlug}
      />
    )
  }
}

export default withUrlSlugs(
  withLocation(ProductsPageRoutingMagento),
)
